<template>
    <section>
        <b-row>
            <div class="col-md-8 col-lg-9">
                <div v-if="isLoaded">
                    <b-card v-if="posts.length > 0">
                        <div class="d-flex flex-row justify-content-between align-items-center">
                            <h4>Posts List</h4>
                        </div>
                        <div class="my">
                            <b-alert v-height-fade.appear :show="this.alert.visible" dismissible class="mb-0" :variant="this.alert.type">
                                <div class="alert-body">
                                    {{ this.alert.text }}
                                </div>
                            </b-alert>
                        </div>
                        <b-table responsive class="mt-1" :striped="striped" :bordered="bordered" :outlined="outlined" :fields="fields" :items="posts">
                            <template #cell(id)="data">
                                {{ data.value }}
                            </template>
                            <template #cell(title)="data">
                                {{ data.value }}
                            </template>
                            <template #cell(featured_image)="data">
                                <b-img
                                    class="featured_image"
                                    :src="data.value ? data.value : require('@/assets/images/pages/posts/post_thumbnail.png')"
                                />
                            </template>
                            <template #cell(status)="data">
                                {{ data.value }}
                            </template>
                            <template #cell(created_at)="data">
                                {{ new Date(data.value).toDateString() }}
                            </template>
                            <template #cell(updated_at)="data">
                                {{ new Date(data.value).toDateString() }}
                            </template>
                            <template #cell(action)="data">
                                <b-button variant="gradient-info" class="btn-icon" v-on:click="postAction(data.item.id)">
                                    <feather-icon icon="Edit2Icon" />
                                </b-button>
                            </template>
                        </b-table>

                        <b-pagination class="mb-0" v-model="page" :per-page="perPage" :total-rows="count" @change="pageChangeHandler"></b-pagination>
                    </b-card>
                    <b-card v-else class="text-center">
                        <h4 class="text-secondary mb-0">
                            Posts Not Found
                        </h4>
                    </b-card>
                </div>
                <div v-else class="text-center my-3">
                    <b-spinner label="Loading..." />
                    <span class="d-block mt-1">
                        Loading Posts...
                    </span>
                </div>
            </div>
            <div class="col-md-4 col-lg-3">
                <b-card>
                    <div class="d-flex flex-row justify-content-between align-items-center">
                        <h4>Search</h4>
                    </div>
                    <b-form class="auth-login-form mt-2" @submit.prevent="searchHandler">
                        <b-form-group label="Title" label-for="searchTitle">
                            <b-form-input v-model="searchTitle" placeholder="Enter post title" />
                        </b-form-group>
                        <b-button type="submit" variant="primary" block>Filter</b-button>
                    </b-form>
                </b-card>
            </div>
        </b-row>
    </section>
</template>

<script>
    import {
        BRow,
        BCard,
        BCardText,
        BImg,
        BButton,
        BSpinner,
        BBadge,
        BTable,
        BFormGroup,
        BFormInput,
        BForm,
        BAlert,
        BPagination,
    } from "bootstrap-vue";
    import { heightFade } from "@core/directives/animations";
    import axios from "axios";
    import _ from "lodash";

    export default {
        directives: {
            "height-fade": heightFade,
        },
        components: {
            BRow,
            BCard,
            BCardText,
            BImg,
            BButton,
            BBadge,
            BTable,
            BFormGroup,
            BFormInput,
            BForm,
            BAlert,
            BSpinner,
            BPagination,
        },
        data() {
            return {
                // table
                page: 1,
                count: 1,
                perPage: 1,
                startNum: 0,
                striped: true,
                bordered: true,
                outlined: true,
                fields: [
                    { key: "id", label: "#" },
                    { key: "title", label: "Title" },
                    { key: "featured_image", label: "Image" },
                    { key: "status", label: "Status" },
                    { key: "created_at", label: "Created At" },
                    { key: "updated_at", label: "Updated At" },
                    { key: "action", label: "Actions" },
                ],
                posts: [],
                // search
                searchTitle: undefined,
                // is posts loaded flag
                isLoaded: false,
            };
        },
        computed: {
            alert: {
                get() {
                    return this.$store.getters["posts/getAlert"];
                },
                set(value) {
                    this.$store.commit("posts/setAlert", value);
                },
            },
        },
        created() {
            if (this.alert.visible) {
                setTimeout(() => {
                    this.$store.commit("posts/hideAlert");
                }, 5000);
            }

            if (this.$route.query.hasOwnProperty("page")) {
                this.page = this.$route.query.page;
            }
            this.getPosts();
        },
        methods: {
            getPosts(params = {}) {
                axios
                    .get("https://api.soclib.net/api/posts", {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                        },
                        params: {
                            ...params,
                            page: this.page,
                        },
                    })
                    .then((response) => {
                        const { total } = response.data;
                        const { per_page } = response.data;
                        this.startNum = (this.page - 1) * this.perPage;
                        const posts = _.values(response.data.data)
                        _.map(posts, (post) => {
                            this.startNum++;
                            post.created_by = this.startNum;
                        });
                        this.perPage = per_page;
                        this.posts = posts;
                        this.count = total;
                        this.isLoaded = true;
                    })
                    .catch((error) => {
                        this.isLoaded = true;
                        
                    });
            },
            postAction(id) {
                this.$router.replace({
                    name: "posts-edit",
                    params: {
                        id,
                    },
                });
            },
            pageChangeHandler(value) {
                this.page = value;
                const params = {
                    search: this.searchTitle,
                };
                this.getPosts(params);
            },
            resetPagination() {
                this.page = 1;
            },
            searchHandler() {
                this.isLoaded = false;
                this.resetPagination();
                const params = {
                    search: this.searchTitle,
                };
                this.getPosts(params);
            },
        },
    };
</script>

<style lang="scss">
    .featured_image {
        width: 30px;
        height: 30px;
        object-fit: cover;
        object-position: center;
    }
</style>
